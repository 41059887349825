import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  JSONObject: any;
};

export type CompleteIdealistResponse = {
  __typename?: 'CompleteIdealistResponse';
  completedCount: Scalars['Int'];
  listId: Scalars['ID'];
  user: User;
};

export enum ContactSubmissionType {
  JoinWaitlist = 'JOIN_WAITLIST',
  ProductFeedback = 'PRODUCT_FEEDBACK',
  SubscriptionCorporate = 'SUBSCRIPTION_CORPORATE',
  SubscriptionMonthly = 'SUBSCRIPTION_MONTHLY',
  SubscriptionYearly = 'SUBSCRIPTION_YEARLY',
}

export type FeedEvent = {
  __typename?: 'FeedEvent';
  createdAt: Scalars['Date'];
  follows?: Maybe<Follows>;
  id: Scalars['ID'];
  list?: Maybe<IdealistWithUserStats>;
  type: FeedEventType;
  user?: Maybe<User>;
};

export type FeedEventEdge = PaginatedEdge & {
  __typename?: 'FeedEventEdge';
  cursor: Scalars['String'];
  node: FeedEvent;
};

export enum FeedEventType {
  AcceptedInvite = 'ACCEPTED_INVITE',
  ListCompleted = 'LIST_COMPLETED',
  ListCreated = 'LIST_CREATED',
  UserFollowed = 'USER_FOLLOWED',
}

export type Follows = {
  __typename?: 'Follows';
  createdAt: Scalars['Date'];
  follower: User;
  following: User;
  id: Scalars['ID'];
};

export type GetUserProfileResponse = {
  __typename?: 'GetUserProfileResponse';
  stats: UserProfileStats;
  user: User;
};

export type IdeaListEdge = PaginatedEdge & {
  __typename?: 'IdeaListEdge';
  cursor: Scalars['String'];
  node: IdealistWithUserStats;
};

export type Idealist = {
  __typename?: 'Idealist';
  completedBy: Array<User>;
  createdAt: Scalars['Date'];
  creator: User;
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  images?: Maybe<Array<IdealistImage>>;
  isDraft: Scalars['Boolean'];
  items: Array<IdealistItem>;
  location?: Maybe<IdealistLocation>;
  title: Scalars['String'];
};

export type IdealistImage = {
  __typename?: 'IdealistImage';
  id: Scalars['ID'];
  src: Scalars['String'];
};

export type IdealistImageInput = {
  id?: InputMaybe<Scalars['ID']>;
  position: Scalars['Int'];
  src: Scalars['String'];
};

export type IdealistInput = {
  description: Scalars['String'];
  images: Array<IdealistImageInput>;
  items: Array<IdealistItemInput>;
  location: Scalars['String'];
  title: Scalars['String'];
};

export type IdealistItem = {
  __typename?: 'IdealistItem';
  description: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Array<IdealistImage>>;
  link?: Maybe<Scalars['String']>;
};

export type IdealistItemInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type IdealistLocation = {
  __typename?: 'IdealistLocation';
  id: Scalars['ID'];
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type IdealistWithUserStats = {
  __typename?: 'IdealistWithUserStats';
  completedByUser: Scalars['Boolean'];
  idealist: Idealist;
  likeCount: Scalars['Int'];
  likedByUser: Scalars['Boolean'];
};

export type InviteLinkResponse = {
  __typename?: 'InviteLinkResponse';
  invitedUsersSignedUpCount: Scalars['Int'];
  link: Scalars['String'];
};

export type LikeOrDislikeIdealistResponse = {
  __typename?: 'LikeOrDislikeIdealistResponse';
  likeCount: Scalars['Int'];
  likedByUser: Scalars['Boolean'];
  listId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContactSubmission: SimpleOk;
  completedIdealist: CompleteIdealistResponse;
  createDraftIdeaList: Scalars['ID'];
  deleteIdealist: Scalars['ID'];
  editIdeaList: Idealist;
  followUser: SimpleOk;
  likeOrDislikeIdealist: LikeOrDislikeIdealistResponse;
  makeIdealistPublic: Scalars['ID'];
  passwordReset: SimpleOk;
  requestPasswordReset: SimpleOk;
  setName: SimpleOk;
  setReferral: SimpleOk;
  setUsername: SimpleOk;
  verifyProfile: SimpleOk;
};

export type MutationAddContactSubmissionArgs = {
  email: Scalars['String'];
  gRecaptchaToken: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  type: ContactSubmissionType;
};

export type MutationCompletedIdealistArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteIdealistArgs = {
  id: Scalars['ID'];
};

export type MutationEditIdeaListArgs = {
  id: Scalars['ID'];
  input: IdealistInput;
};

export type MutationFollowUserArgs = {
  userId: Scalars['ID'];
};

export type MutationLikeOrDislikeIdealistArgs = {
  id: Scalars['ID'];
  like: Scalars['Boolean'];
};

export type MutationMakeIdealistPublicArgs = {
  id: Scalars['ID'];
};

export type MutationPasswordResetArgs = {
  gRecaptchaToken: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationRequestPasswordResetArgs = {
  username: Scalars['String'];
};

export type MutationSetNameArgs = {
  name: Scalars['String'];
};

export type MutationSetReferralArgs = {
  referralId: Scalars['String'];
};

export type MutationSetUsernameArgs = {
  username: Scalars['String'];
};

export type MutationVerifyProfileArgs = {
  token: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedConnection = {
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedEdge = {
  cursor: Scalars['String'];
};

export type PaginatedFeedEvents = PaginatedConnection & {
  __typename?: 'PaginatedFeedEvents';
  edges: Array<FeedEventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedIdeaLists = PaginatedConnection & {
  __typename?: 'PaginatedIdeaLists';
  edges: Array<IdeaListEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  getIdeaList: IdealistWithUserStats;
  getInviteLink: InviteLinkResponse;
  getInviteLinkForList: InviteLinkResponse;
  getPublicIdeaList: IdealistWithUserStats;
  getStrings: Array<Scalars['String']>;
  getTopUsers: Array<User>;
  getUserProfile: GetUserProfileResponse;
  isEmailVerified: SimpleOk;
  paginatedFeedEvents: PaginatedFeedEvents;
  paginatedIdeaLists: PaginatedIdeaLists;
  verifyInviteLink: VerifyInviteLinkResponse;
  verifyPasswordReset: SimpleOk;
};

export type QueryGetIdeaListArgs = {
  id: Scalars['ID'];
};

export type QueryGetInviteLinkForListArgs = {
  gRecaptchaToken: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryGetPublicIdeaListArgs = {
  gRecaptchaToken: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryGetStringsArgs = {
  count: Scalars['Int'];
};

export type QueryGetTopUsersArgs = {
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetUserProfileArgs = {
  username: Scalars['String'];
};

export type QueryPaginatedFeedEventsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryPaginatedIdeaListsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  includeDraft?: InputMaybe<Scalars['Boolean']>;
  searchInput?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryVerifyInviteLinkArgs = {
  id: Scalars['String'];
};

export type QueryVerifyPasswordResetArgs = {
  token: Scalars['String'];
};

export type SimpleOk = {
  __typename?: 'SimpleOK';
  ok: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type UserProfileStats = {
  __typename?: 'UserProfileStats';
  followersCount: Scalars['Int'];
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  rank: Scalars['Int'];
  score: Scalars['Int'];
};

export type VerifyInviteLinkResponse = {
  __typename?: 'VerifyInviteLinkResponse';
  user: User;
  valid: Scalars['Boolean'];
};

export type LikeOrDislikeIdealistMutationVariables = Exact<{
  id: Scalars['ID'];
  like: Scalars['Boolean'];
}>;

export type LikeOrDislikeIdealistMutation = {
  __typename?: 'Mutation';
  likeOrDislikeIdealist: {
    __typename?: 'LikeOrDislikeIdealistResponse';
    listId: string;
    likeCount: number;
    likedByUser: boolean;
  };
};

export type CompleteIdealistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompleteIdealistMutation = {
  __typename?: 'Mutation';
  completedIdealist: {
    __typename?: 'CompleteIdealistResponse';
    listId: string;
    completedCount: number;
    user: {
      __typename?: 'User';
      name?: string | null;
      id: string;
      username?: string | null;
      image?: string | null;
    };
  };
};

export type GetFeedEventsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
}>;

export type GetFeedEventsQuery = {
  __typename?: 'Query';
  paginatedFeedEvents: {
    __typename?: 'PaginatedFeedEvents';
    totalCount: number;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: 'FeedEventEdge';
      cursor: string;
      node: {
        __typename?: 'FeedEvent';
        id: string;
        createdAt: any;
        type: FeedEventType;
        list?: {
          __typename?: 'IdealistWithUserStats';
          likeCount: number;
          likedByUser: boolean;
          completedByUser: boolean;
          idealist: {
            __typename?: 'Idealist';
            id: string;
            createdAt: any;
            title: string;
            description: string;
            image?: string | null;
            isDraft: boolean;
            creator: {
              __typename?: 'User';
              id: string;
              username?: string | null;
              image?: string | null;
            };
            location?: { __typename?: 'IdealistLocation'; id: string; name: string } | null;
            completedBy: Array<{
              __typename?: 'User';
              name?: string | null;
              id: string;
              username?: string | null;
              image?: string | null;
            }>;
          };
        } | null;
        follows?: {
          __typename?: 'Follows';
          id: string;
          createdAt: any;
          follower: {
            __typename?: 'User';
            id: string;
            username?: string | null;
            image?: string | null;
          };
        } | null;
        user?: {
          __typename?: 'User';
          id: string;
          username?: string | null;
          image?: string | null;
        } | null;
      };
    }>;
  };
};

export type EditIdeaListMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IdealistInput;
}>;

export type EditIdeaListMutation = {
  __typename?: 'Mutation';
  editIdeaList: {
    __typename?: 'Idealist';
    id: string;
    createdAt: any;
    title: string;
    image?: string | null;
    description: string;
    isDraft: boolean;
    items: Array<{ __typename?: 'IdealistItem'; id: string; description: string }>;
    location?: {
      __typename?: 'IdealistLocation';
      id: string;
      name: string;
      lat?: string | null;
      long?: string | null;
    } | null;
    images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
  };
};

export type CreateDraftIdeaListMutationVariables = Exact<{ [key: string]: never }>;

export type CreateDraftIdeaListMutation = { __typename?: 'Mutation'; createDraftIdeaList: string };

export type GetIdeaListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetIdeaListQuery = {
  __typename?: 'Query';
  getIdeaList: {
    __typename?: 'IdealistWithUserStats';
    likeCount: number;
    likedByUser: boolean;
    completedByUser: boolean;
    idealist: {
      __typename?: 'Idealist';
      id: string;
      createdAt: any;
      title: string;
      image?: string | null;
      description: string;
      isDraft: boolean;
      items: Array<{
        __typename?: 'IdealistItem';
        id: string;
        description: string;
        link?: string | null;
        images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
      }>;
      location?: {
        __typename?: 'IdealistLocation';
        id: string;
        name: string;
        lat?: string | null;
        long?: string | null;
      } | null;
      creator: {
        __typename?: 'User';
        name?: string | null;
        id: string;
        username?: string | null;
        image?: string | null;
      };
      completedBy: Array<{
        __typename?: 'User';
        name?: string | null;
        id: string;
        username?: string | null;
        image?: string | null;
      }>;
      images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
    };
  };
};

export type DeleteIdealistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIdealistMutation = { __typename?: 'Mutation'; deleteIdealist: string };

export type MakeIdealistPublicMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MakeIdealistPublicMutation = { __typename?: 'Mutation'; makeIdealistPublic: string };

export type GetPublicIdeaListQueryVariables = Exact<{
  id: Scalars['ID'];
  gRecaptchaToken: Scalars['String'];
}>;

export type GetPublicIdeaListQuery = {
  __typename?: 'Query';
  getPublicIdeaList: {
    __typename?: 'IdealistWithUserStats';
    likeCount: number;
    idealist: {
      __typename?: 'Idealist';
      id: string;
      createdAt: any;
      title: string;
      image?: string | null;
      description: string;
      isDraft: boolean;
      items: Array<{
        __typename?: 'IdealistItem';
        id: string;
        description: string;
        link?: string | null;
        images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
      }>;
      location?: {
        __typename?: 'IdealistLocation';
        id: string;
        name: string;
        lat?: string | null;
        long?: string | null;
      } | null;
      creator: {
        __typename?: 'User';
        name?: string | null;
        id: string;
        username?: string | null;
        image?: string | null;
      };
      completedBy: Array<{
        __typename?: 'User';
        name?: string | null;
        id: string;
        username?: string | null;
        image?: string | null;
      }>;
      images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
    };
  };
};

export type GetInviteLinkForListQueryVariables = Exact<{
  id: Scalars['ID'];
  gRecaptchaToken: Scalars['String'];
}>;

export type GetInviteLinkForListQuery = {
  __typename?: 'Query';
  getInviteLinkForList: { __typename?: 'InviteLinkResponse'; link: string };
};

export type GetIdealistsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
  includeDraft?: InputMaybe<Scalars['Boolean']>;
  searchInput?: InputMaybe<Scalars['String']>;
}>;

export type GetIdealistsQuery = {
  __typename?: 'Query';
  paginatedIdeaLists: {
    __typename?: 'PaginatedIdeaLists';
    totalCount: number;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: 'IdeaListEdge';
      cursor: string;
      node: {
        __typename?: 'IdealistWithUserStats';
        likeCount: number;
        likedByUser: boolean;
        completedByUser: boolean;
        idealist: {
          __typename?: 'Idealist';
          id: string;
          createdAt: any;
          title: string;
          description: string;
          image?: string | null;
          isDraft: boolean;
          creator: {
            __typename?: 'User';
            id: string;
            username?: string | null;
            image?: string | null;
          };
          location?: { __typename?: 'IdealistLocation'; id: string; name: string } | null;
          completedBy: Array<{
            __typename?: 'User';
            name?: string | null;
            id: string;
            username?: string | null;
            image?: string | null;
          }>;
        };
      };
    }>;
  };
};

export type AddContactSubmissionMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  message: Scalars['String'];
  type: ContactSubmissionType;
  gRecaptchaToken: Scalars['String'];
}>;

export type AddContactSubmissionMutation = {
  __typename?: 'Mutation';
  addContactSubmission: { __typename?: 'SimpleOK'; ok: boolean };
};

export type CompleteProfileMutationVariables = Exact<{
  username: Scalars['String'];
  name: Scalars['String'];
}>;

export type CompleteProfileMutation = {
  __typename?: 'Mutation';
  setUsername: { __typename?: 'SimpleOK'; ok: boolean };
  setName: { __typename?: 'SimpleOK'; ok: boolean };
};

export type IsEmailVerifiedQueryVariables = Exact<{ [key: string]: never }>;

export type IsEmailVerifiedQuery = {
  __typename?: 'Query';
  isEmailVerified: { __typename?: 'SimpleOK'; ok: boolean };
};

export type VerifyProfileMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyProfileMutation = {
  __typename?: 'Mutation';
  verifyProfile: { __typename?: 'SimpleOK'; ok: boolean };
};

export type RequestPasswordResetMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'Mutation';
  requestPasswordReset: { __typename?: 'SimpleOK'; ok: boolean };
};

export type VerifyPasswordResetQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyPasswordResetQuery = {
  __typename?: 'Query';
  verifyPasswordReset: { __typename?: 'SimpleOK'; ok: boolean };
};

export type PasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  gRecaptchaToken: Scalars['String'];
}>;

export type PasswordResetMutation = {
  __typename?: 'Mutation';
  passwordReset: { __typename?: 'SimpleOK'; ok: boolean };
};

export type GetInviteLinkQueryVariables = Exact<{ [key: string]: never }>;

export type GetInviteLinkQuery = {
  __typename?: 'Query';
  getInviteLink: {
    __typename?: 'InviteLinkResponse';
    link: string;
    invitedUsersSignedUpCount: number;
  };
};

export type VerifyInviteLinkQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type VerifyInviteLinkQuery = {
  __typename?: 'Query';
  verifyInviteLink: {
    __typename?: 'VerifyInviteLinkResponse';
    valid: boolean;
    user: { __typename?: 'User'; id: string; name?: string | null };
  };
};

export type SetReferralMutationVariables = Exact<{
  referralId: Scalars['String'];
}>;

export type SetReferralMutation = {
  __typename?: 'Mutation';
  setReferral: { __typename?: 'SimpleOK'; ok: boolean };
};

export type GetTopUsersQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']>;
}>;

export type GetTopUsersQuery = {
  __typename?: 'Query';
  getTopUsers: Array<{
    __typename?: 'User';
    score?: number | null;
    id: string;
    username?: string | null;
    image?: string | null;
  }>;
};

export type GetUserProfileQueryVariables = Exact<{
  username: Scalars['String'];
}>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  getUserProfile: {
    __typename?: 'GetUserProfileResponse';
    user: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      username?: string | null;
      image?: string | null;
    };
    stats: {
      __typename?: 'UserProfileStats';
      id: string;
      rank: number;
      score: number;
      followersCount: number;
      following: boolean;
    };
  };
};

export type FollowUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type FollowUserMutation = {
  __typename?: 'Mutation';
  followUser: { __typename?: 'SimpleOK'; ok: boolean };
};

export type UserCoreFragment = {
  __typename?: 'User';
  id: string;
  username?: string | null;
  image?: string | null;
};

export type IdealistItemCoreFragment = {
  __typename?: 'IdealistItem';
  id: string;
  description: string;
  link?: string | null;
  images?: Array<{ __typename?: 'IdealistImage'; id: string; src: string }> | null;
};

export type IdealistLocationCoreFragment = {
  __typename?: 'IdealistLocation';
  id: string;
  name: string;
  lat?: string | null;
  long?: string | null;
};

export type IdealistCoreFragment = {
  __typename?: 'Idealist';
  id: string;
  createdAt: any;
  title: string;
  image?: string | null;
  description: string;
  isDraft: boolean;
};

export type FeedIdealistWithUserStatsFragment = {
  __typename?: 'IdealistWithUserStats';
  likeCount: number;
  likedByUser: boolean;
  completedByUser: boolean;
  idealist: {
    __typename?: 'Idealist';
    id: string;
    createdAt: any;
    title: string;
    description: string;
    image?: string | null;
    isDraft: boolean;
    creator: { __typename?: 'User'; id: string; username?: string | null; image?: string | null };
    location?: { __typename?: 'IdealistLocation'; id: string; name: string } | null;
    completedBy: Array<{
      __typename?: 'User';
      name?: string | null;
      id: string;
      username?: string | null;
      image?: string | null;
    }>;
  };
};

export const IdealistItemCoreFragmentDoc = gql`
  fragment IdealistItemCore on IdealistItem {
    id
    description
    link
    images {
      id
      src
    }
  }
`;
export const IdealistLocationCoreFragmentDoc = gql`
  fragment IdealistLocationCore on IdealistLocation {
    id
    name
    lat
    long
  }
`;
export const IdealistCoreFragmentDoc = gql`
  fragment IdealistCore on Idealist {
    id
    createdAt
    title
    image
    description
    isDraft
  }
`;
export const UserCoreFragmentDoc = gql`
  fragment UserCore on User {
    id
    username
    image
  }
`;
export const FeedIdealistWithUserStatsFragmentDoc = gql`
  fragment FeedIdealistWithUserStats on IdealistWithUserStats {
    idealist {
      id
      createdAt
      title
      description
      image
      isDraft
      creator {
        ...UserCore
      }
      location {
        id
        name
      }
      completedBy {
        name
        ...UserCore
      }
    }
    likeCount
    likedByUser
    completedByUser
  }
`;
export const LikeOrDislikeIdealistDocument = gql`
  mutation LikeOrDislikeIdealist($id: ID!, $like: Boolean!) {
    likeOrDislikeIdealist(id: $id, like: $like) {
      listId
      likeCount
      likedByUser
    }
  }
`;
export type LikeOrDislikeIdealistMutationFn = Apollo.MutationFunction<
  LikeOrDislikeIdealistMutation,
  LikeOrDislikeIdealistMutationVariables
>;

/**
 * __useLikeOrDislikeIdealistMutation__
 *
 * To run a mutation, you first call `useLikeOrDislikeIdealistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeOrDislikeIdealistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeOrDislikeIdealistMutation, { data, loading, error }] = useLikeOrDislikeIdealistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      like: // value for 'like'
 *   },
 * });
 */
export function useLikeOrDislikeIdealistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LikeOrDislikeIdealistMutation,
    LikeOrDislikeIdealistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LikeOrDislikeIdealistMutation, LikeOrDislikeIdealistMutationVariables>(
    LikeOrDislikeIdealistDocument,
    options,
  );
}
export type LikeOrDislikeIdealistMutationHookResult = ReturnType<
  typeof useLikeOrDislikeIdealistMutation
>;
export type LikeOrDislikeIdealistMutationResult =
  Apollo.MutationResult<LikeOrDislikeIdealistMutation>;
export type LikeOrDislikeIdealistMutationOptions = Apollo.BaseMutationOptions<
  LikeOrDislikeIdealistMutation,
  LikeOrDislikeIdealistMutationVariables
>;
export const CompleteIdealistDocument = gql`
  mutation CompleteIdealist($id: ID!) {
    completedIdealist(id: $id) {
      listId
      completedCount
      user {
        name
        ...UserCore
      }
    }
  }
  ${UserCoreFragmentDoc}
`;
export type CompleteIdealistMutationFn = Apollo.MutationFunction<
  CompleteIdealistMutation,
  CompleteIdealistMutationVariables
>;

/**
 * __useCompleteIdealistMutation__
 *
 * To run a mutation, you first call `useCompleteIdealistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteIdealistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeIdealistMutation, { data, loading, error }] = useCompleteIdealistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteIdealistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteIdealistMutation,
    CompleteIdealistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteIdealistMutation, CompleteIdealistMutationVariables>(
    CompleteIdealistDocument,
    options,
  );
}
export type CompleteIdealistMutationHookResult = ReturnType<typeof useCompleteIdealistMutation>;
export type CompleteIdealistMutationResult = Apollo.MutationResult<CompleteIdealistMutation>;
export type CompleteIdealistMutationOptions = Apollo.BaseMutationOptions<
  CompleteIdealistMutation,
  CompleteIdealistMutationVariables
>;
export const GetFeedEventsDocument = gql`
  query GetFeedEvents($after: ID, $take: Int, $userId: ID) {
    paginatedFeedEvents(after: $after, take: $take, userId: $userId) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          createdAt
          type
          list {
            ...FeedIdealistWithUserStats
          }
          follows {
            id
            createdAt
            follower {
              ...UserCore
            }
          }
          user {
            ...UserCore
          }
        }
      }
    }
  }
  ${FeedIdealistWithUserStatsFragmentDoc}
  ${UserCoreFragmentDoc}
`;

/**
 * __useGetFeedEventsQuery__
 *
 * To run a query within a React component, call `useGetFeedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedEventsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      take: // value for 'take'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFeedEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFeedEventsQuery, GetFeedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedEventsQuery, GetFeedEventsQueryVariables>(
    GetFeedEventsDocument,
    options,
  );
}
export function useGetFeedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeedEventsQuery, GetFeedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedEventsQuery, GetFeedEventsQueryVariables>(
    GetFeedEventsDocument,
    options,
  );
}
export type GetFeedEventsQueryHookResult = ReturnType<typeof useGetFeedEventsQuery>;
export type GetFeedEventsLazyQueryHookResult = ReturnType<typeof useGetFeedEventsLazyQuery>;
export type GetFeedEventsQueryResult = Apollo.QueryResult<
  GetFeedEventsQuery,
  GetFeedEventsQueryVariables
>;
export const EditIdeaListDocument = gql`
  mutation EditIdeaList($id: ID!, $input: IdealistInput!) {
    editIdeaList(id: $id, input: $input) {
      ...IdealistCore
      items {
        id
        description
      }
      location {
        ...IdealistLocationCore
      }
      images {
        id
        src
      }
    }
  }
  ${IdealistCoreFragmentDoc}
  ${IdealistLocationCoreFragmentDoc}
`;
export type EditIdeaListMutationFn = Apollo.MutationFunction<
  EditIdeaListMutation,
  EditIdeaListMutationVariables
>;

/**
 * __useEditIdeaListMutation__
 *
 * To run a mutation, you first call `useEditIdeaListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIdeaListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIdeaListMutation, { data, loading, error }] = useEditIdeaListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditIdeaListMutation(
  baseOptions?: Apollo.MutationHookOptions<EditIdeaListMutation, EditIdeaListMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditIdeaListMutation, EditIdeaListMutationVariables>(
    EditIdeaListDocument,
    options,
  );
}
export type EditIdeaListMutationHookResult = ReturnType<typeof useEditIdeaListMutation>;
export type EditIdeaListMutationResult = Apollo.MutationResult<EditIdeaListMutation>;
export type EditIdeaListMutationOptions = Apollo.BaseMutationOptions<
  EditIdeaListMutation,
  EditIdeaListMutationVariables
>;
export const CreateDraftIdeaListDocument = gql`
  mutation CreateDraftIdeaList {
    createDraftIdeaList
  }
`;
export type CreateDraftIdeaListMutationFn = Apollo.MutationFunction<
  CreateDraftIdeaListMutation,
  CreateDraftIdeaListMutationVariables
>;

/**
 * __useCreateDraftIdeaListMutation__
 *
 * To run a mutation, you first call `useCreateDraftIdeaListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftIdeaListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftIdeaListMutation, { data, loading, error }] = useCreateDraftIdeaListMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDraftIdeaListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftIdeaListMutation,
    CreateDraftIdeaListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDraftIdeaListMutation, CreateDraftIdeaListMutationVariables>(
    CreateDraftIdeaListDocument,
    options,
  );
}
export type CreateDraftIdeaListMutationHookResult = ReturnType<
  typeof useCreateDraftIdeaListMutation
>;
export type CreateDraftIdeaListMutationResult = Apollo.MutationResult<CreateDraftIdeaListMutation>;
export type CreateDraftIdeaListMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftIdeaListMutation,
  CreateDraftIdeaListMutationVariables
>;
export const GetIdeaListDocument = gql`
  query GetIdeaList($id: ID!) {
    getIdeaList(id: $id) {
      idealist {
        ...IdealistCore
        items {
          ...IdealistItemCore
        }
        location {
          ...IdealistLocationCore
        }
        creator {
          name
          ...UserCore
        }
        completedBy {
          name
          ...UserCore
        }
        images {
          id
          src
        }
      }
      likeCount
      likedByUser
      completedByUser
    }
  }
  ${IdealistCoreFragmentDoc}
  ${IdealistItemCoreFragmentDoc}
  ${IdealistLocationCoreFragmentDoc}
  ${UserCoreFragmentDoc}
`;

/**
 * __useGetIdeaListQuery__
 *
 * To run a query within a React component, call `useGetIdeaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdeaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdeaListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIdeaListQuery(
  baseOptions: Apollo.QueryHookOptions<GetIdeaListQuery, GetIdeaListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdeaListQuery, GetIdeaListQueryVariables>(GetIdeaListDocument, options);
}
export function useGetIdeaListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIdeaListQuery, GetIdeaListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdeaListQuery, GetIdeaListQueryVariables>(
    GetIdeaListDocument,
    options,
  );
}
export type GetIdeaListQueryHookResult = ReturnType<typeof useGetIdeaListQuery>;
export type GetIdeaListLazyQueryHookResult = ReturnType<typeof useGetIdeaListLazyQuery>;
export type GetIdeaListQueryResult = Apollo.QueryResult<
  GetIdeaListQuery,
  GetIdeaListQueryVariables
>;
export const DeleteIdealistDocument = gql`
  mutation DeleteIdealist($id: ID!) {
    deleteIdealist(id: $id)
  }
`;
export type DeleteIdealistMutationFn = Apollo.MutationFunction<
  DeleteIdealistMutation,
  DeleteIdealistMutationVariables
>;

/**
 * __useDeleteIdealistMutation__
 *
 * To run a mutation, you first call `useDeleteIdealistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIdealistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIdealistMutation, { data, loading, error }] = useDeleteIdealistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIdealistMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteIdealistMutation, DeleteIdealistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIdealistMutation, DeleteIdealistMutationVariables>(
    DeleteIdealistDocument,
    options,
  );
}
export type DeleteIdealistMutationHookResult = ReturnType<typeof useDeleteIdealistMutation>;
export type DeleteIdealistMutationResult = Apollo.MutationResult<DeleteIdealistMutation>;
export type DeleteIdealistMutationOptions = Apollo.BaseMutationOptions<
  DeleteIdealistMutation,
  DeleteIdealistMutationVariables
>;
export const MakeIdealistPublicDocument = gql`
  mutation MakeIdealistPublic($id: ID!) {
    makeIdealistPublic(id: $id)
  }
`;
export type MakeIdealistPublicMutationFn = Apollo.MutationFunction<
  MakeIdealistPublicMutation,
  MakeIdealistPublicMutationVariables
>;

/**
 * __useMakeIdealistPublicMutation__
 *
 * To run a mutation, you first call `useMakeIdealistPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeIdealistPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeIdealistPublicMutation, { data, loading, error }] = useMakeIdealistPublicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeIdealistPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeIdealistPublicMutation,
    MakeIdealistPublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeIdealistPublicMutation, MakeIdealistPublicMutationVariables>(
    MakeIdealistPublicDocument,
    options,
  );
}
export type MakeIdealistPublicMutationHookResult = ReturnType<typeof useMakeIdealistPublicMutation>;
export type MakeIdealistPublicMutationResult = Apollo.MutationResult<MakeIdealistPublicMutation>;
export type MakeIdealistPublicMutationOptions = Apollo.BaseMutationOptions<
  MakeIdealistPublicMutation,
  MakeIdealistPublicMutationVariables
>;
export const GetPublicIdeaListDocument = gql`
  query GetPublicIdeaList($id: ID!, $gRecaptchaToken: String!) {
    getPublicIdeaList(id: $id, gRecaptchaToken: $gRecaptchaToken) {
      idealist {
        ...IdealistCore
        items {
          ...IdealistItemCore
        }
        location {
          ...IdealistLocationCore
        }
        creator {
          name
          ...UserCore
        }
        completedBy {
          name
          ...UserCore
        }
        images {
          id
          src
        }
      }
      likeCount
    }
  }
  ${IdealistCoreFragmentDoc}
  ${IdealistItemCoreFragmentDoc}
  ${IdealistLocationCoreFragmentDoc}
  ${UserCoreFragmentDoc}
`;

/**
 * __useGetPublicIdeaListQuery__
 *
 * To run a query within a React component, call `useGetPublicIdeaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicIdeaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicIdeaListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      gRecaptchaToken: // value for 'gRecaptchaToken'
 *   },
 * });
 */
export function useGetPublicIdeaListQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicIdeaListQuery, GetPublicIdeaListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicIdeaListQuery, GetPublicIdeaListQueryVariables>(
    GetPublicIdeaListDocument,
    options,
  );
}
export function useGetPublicIdeaListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicIdeaListQuery,
    GetPublicIdeaListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicIdeaListQuery, GetPublicIdeaListQueryVariables>(
    GetPublicIdeaListDocument,
    options,
  );
}
export type GetPublicIdeaListQueryHookResult = ReturnType<typeof useGetPublicIdeaListQuery>;
export type GetPublicIdeaListLazyQueryHookResult = ReturnType<typeof useGetPublicIdeaListLazyQuery>;
export type GetPublicIdeaListQueryResult = Apollo.QueryResult<
  GetPublicIdeaListQuery,
  GetPublicIdeaListQueryVariables
>;
export const GetInviteLinkForListDocument = gql`
  query GetInviteLinkForList($id: ID!, $gRecaptchaToken: String!) {
    getInviteLinkForList(id: $id, gRecaptchaToken: $gRecaptchaToken) {
      link
    }
  }
`;

/**
 * __useGetInviteLinkForListQuery__
 *
 * To run a query within a React component, call `useGetInviteLinkForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteLinkForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteLinkForListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      gRecaptchaToken: // value for 'gRecaptchaToken'
 *   },
 * });
 */
export function useGetInviteLinkForListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInviteLinkForListQuery,
    GetInviteLinkForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInviteLinkForListQuery, GetInviteLinkForListQueryVariables>(
    GetInviteLinkForListDocument,
    options,
  );
}
export function useGetInviteLinkForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInviteLinkForListQuery,
    GetInviteLinkForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInviteLinkForListQuery, GetInviteLinkForListQueryVariables>(
    GetInviteLinkForListDocument,
    options,
  );
}
export type GetInviteLinkForListQueryHookResult = ReturnType<typeof useGetInviteLinkForListQuery>;
export type GetInviteLinkForListLazyQueryHookResult = ReturnType<
  typeof useGetInviteLinkForListLazyQuery
>;
export type GetInviteLinkForListQueryResult = Apollo.QueryResult<
  GetInviteLinkForListQuery,
  GetInviteLinkForListQueryVariables
>;
export const GetIdealistsDocument = gql`
  query GetIdealists(
    $after: ID
    $take: Int
    $userId: ID
    $includeDraft: Boolean
    $searchInput: String
  ) {
    paginatedIdeaLists(
      after: $after
      take: $take
      userId: $userId
      includeDraft: $includeDraft
      searchInput: $searchInput
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          ...FeedIdealistWithUserStats
        }
      }
    }
  }
  ${FeedIdealistWithUserStatsFragmentDoc}
  ${UserCoreFragmentDoc}
`;

/**
 * __useGetIdealistsQuery__
 *
 * To run a query within a React component, call `useGetIdealistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdealistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdealistsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      take: // value for 'take'
 *      userId: // value for 'userId'
 *      includeDraft: // value for 'includeDraft'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetIdealistsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIdealistsQuery, GetIdealistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdealistsQuery, GetIdealistsQueryVariables>(
    GetIdealistsDocument,
    options,
  );
}
export function useGetIdealistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIdealistsQuery, GetIdealistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdealistsQuery, GetIdealistsQueryVariables>(
    GetIdealistsDocument,
    options,
  );
}
export type GetIdealistsQueryHookResult = ReturnType<typeof useGetIdealistsQuery>;
export type GetIdealistsLazyQueryHookResult = ReturnType<typeof useGetIdealistsLazyQuery>;
export type GetIdealistsQueryResult = Apollo.QueryResult<
  GetIdealistsQuery,
  GetIdealistsQueryVariables
>;
export const AddContactSubmissionDocument = gql`
  mutation AddContactSubmission(
    $email: String!
    $name: String!
    $message: String!
    $type: ContactSubmissionType!
    $gRecaptchaToken: String!
  ) {
    addContactSubmission(
      email: $email
      name: $name
      message: $message
      type: $type
      gRecaptchaToken: $gRecaptchaToken
    ) {
      ok
    }
  }
`;
export type AddContactSubmissionMutationFn = Apollo.MutationFunction<
  AddContactSubmissionMutation,
  AddContactSubmissionMutationVariables
>;

/**
 * __useAddContactSubmissionMutation__
 *
 * To run a mutation, you first call `useAddContactSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactSubmissionMutation, { data, loading, error }] = useAddContactSubmissionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      gRecaptchaToken: // value for 'gRecaptchaToken'
 *   },
 * });
 */
export function useAddContactSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContactSubmissionMutation,
    AddContactSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContactSubmissionMutation, AddContactSubmissionMutationVariables>(
    AddContactSubmissionDocument,
    options,
  );
}
export type AddContactSubmissionMutationHookResult = ReturnType<
  typeof useAddContactSubmissionMutation
>;
export type AddContactSubmissionMutationResult =
  Apollo.MutationResult<AddContactSubmissionMutation>;
export type AddContactSubmissionMutationOptions = Apollo.BaseMutationOptions<
  AddContactSubmissionMutation,
  AddContactSubmissionMutationVariables
>;
export const CompleteProfileDocument = gql`
  mutation CompleteProfile($username: String!, $name: String!) {
    setUsername(username: $username) {
      ok
    }
    setName(name: $name) {
      ok
    }
  }
`;
export type CompleteProfileMutationFn = Apollo.MutationFunction<
  CompleteProfileMutation,
  CompleteProfileMutationVariables
>;

/**
 * __useCompleteProfileMutation__
 *
 * To run a mutation, you first call `useCompleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProfileMutation, { data, loading, error }] = useCompleteProfileMutation({
 *   variables: {
 *      username: // value for 'username'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCompleteProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProfileMutation,
    CompleteProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteProfileMutation, CompleteProfileMutationVariables>(
    CompleteProfileDocument,
    options,
  );
}
export type CompleteProfileMutationHookResult = ReturnType<typeof useCompleteProfileMutation>;
export type CompleteProfileMutationResult = Apollo.MutationResult<CompleteProfileMutation>;
export type CompleteProfileMutationOptions = Apollo.BaseMutationOptions<
  CompleteProfileMutation,
  CompleteProfileMutationVariables
>;
export const IsEmailVerifiedDocument = gql`
  query IsEmailVerified {
    isEmailVerified {
      ok
    }
  }
`;

/**
 * __useIsEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useIsEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEmailVerifiedQuery(
  baseOptions?: Apollo.QueryHookOptions<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
    IsEmailVerifiedDocument,
    options,
  );
}
export function useIsEmailVerifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
    IsEmailVerifiedDocument,
    options,
  );
}
export type IsEmailVerifiedQueryHookResult = ReturnType<typeof useIsEmailVerifiedQuery>;
export type IsEmailVerifiedLazyQueryHookResult = ReturnType<typeof useIsEmailVerifiedLazyQuery>;
export type IsEmailVerifiedQueryResult = Apollo.QueryResult<
  IsEmailVerifiedQuery,
  IsEmailVerifiedQueryVariables
>;
export const VerifyProfileDocument = gql`
  mutation VerifyProfile($token: String!) {
    verifyProfile(token: $token) {
      ok
    }
  }
`;
export type VerifyProfileMutationFn = Apollo.MutationFunction<
  VerifyProfileMutation,
  VerifyProfileMutationVariables
>;

/**
 * __useVerifyProfileMutation__
 *
 * To run a mutation, you first call `useVerifyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyProfileMutation, { data, loading, error }] = useVerifyProfileMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyProfileMutation, VerifyProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyProfileMutation, VerifyProfileMutationVariables>(
    VerifyProfileDocument,
    options,
  );
}
export type VerifyProfileMutationHookResult = ReturnType<typeof useVerifyProfileMutation>;
export type VerifyProfileMutationResult = Apollo.MutationResult<VerifyProfileMutation>;
export type VerifyProfileMutationOptions = Apollo.BaseMutationOptions<
  VerifyProfileMutation,
  VerifyProfileMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordReset(username: $username) {
      ok
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options,
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const VerifyPasswordResetDocument = gql`
  query VerifyPasswordReset($token: String!) {
    verifyPasswordReset(token: $token) {
      ok
    }
  }
`;

/**
 * __useVerifyPasswordResetQuery__
 *
 * To run a query within a React component, call `useVerifyPasswordResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasswordResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyPasswordResetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyPasswordResetQuery(
  baseOptions: Apollo.QueryHookOptions<VerifyPasswordResetQuery, VerifyPasswordResetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyPasswordResetQuery, VerifyPasswordResetQueryVariables>(
    VerifyPasswordResetDocument,
    options,
  );
}
export function useVerifyPasswordResetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyPasswordResetQuery,
    VerifyPasswordResetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyPasswordResetQuery, VerifyPasswordResetQueryVariables>(
    VerifyPasswordResetDocument,
    options,
  );
}
export type VerifyPasswordResetQueryHookResult = ReturnType<typeof useVerifyPasswordResetQuery>;
export type VerifyPasswordResetLazyQueryHookResult = ReturnType<
  typeof useVerifyPasswordResetLazyQuery
>;
export type VerifyPasswordResetQueryResult = Apollo.QueryResult<
  VerifyPasswordResetQuery,
  VerifyPasswordResetQueryVariables
>;
export const PasswordResetDocument = gql`
  mutation PasswordReset($token: String!, $password: String!, $gRecaptchaToken: String!) {
    passwordReset(token: $token, password: $password, gRecaptchaToken: $gRecaptchaToken) {
      ok
    }
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      gRecaptchaToken: // value for 'gRecaptchaToken'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(
    PasswordResetDocument,
    options,
  );
}
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const GetInviteLinkDocument = gql`
  query GetInviteLink {
    getInviteLink {
      link
      invitedUsersSignedUpCount
    }
  }
`;

/**
 * __useGetInviteLinkQuery__
 *
 * To run a query within a React component, call `useGetInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInviteLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInviteLinkQuery, GetInviteLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInviteLinkQuery, GetInviteLinkQueryVariables>(
    GetInviteLinkDocument,
    options,
  );
}
export function useGetInviteLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInviteLinkQuery, GetInviteLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInviteLinkQuery, GetInviteLinkQueryVariables>(
    GetInviteLinkDocument,
    options,
  );
}
export type GetInviteLinkQueryHookResult = ReturnType<typeof useGetInviteLinkQuery>;
export type GetInviteLinkLazyQueryHookResult = ReturnType<typeof useGetInviteLinkLazyQuery>;
export type GetInviteLinkQueryResult = Apollo.QueryResult<
  GetInviteLinkQuery,
  GetInviteLinkQueryVariables
>;
export const VerifyInviteLinkDocument = gql`
  query verifyInviteLink($id: String!) {
    verifyInviteLink(id: $id) {
      valid
      user {
        id
        name
      }
    }
  }
`;

/**
 * __useVerifyInviteLinkQuery__
 *
 * To run a query within a React component, call `useVerifyInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyInviteLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyInviteLinkQuery(
  baseOptions: Apollo.QueryHookOptions<VerifyInviteLinkQuery, VerifyInviteLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyInviteLinkQuery, VerifyInviteLinkQueryVariables>(
    VerifyInviteLinkDocument,
    options,
  );
}
export function useVerifyInviteLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerifyInviteLinkQuery, VerifyInviteLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyInviteLinkQuery, VerifyInviteLinkQueryVariables>(
    VerifyInviteLinkDocument,
    options,
  );
}
export type VerifyInviteLinkQueryHookResult = ReturnType<typeof useVerifyInviteLinkQuery>;
export type VerifyInviteLinkLazyQueryHookResult = ReturnType<typeof useVerifyInviteLinkLazyQuery>;
export type VerifyInviteLinkQueryResult = Apollo.QueryResult<
  VerifyInviteLinkQuery,
  VerifyInviteLinkQueryVariables
>;
export const SetReferralDocument = gql`
  mutation setReferral($referralId: String!) {
    setReferral(referralId: $referralId) {
      ok
    }
  }
`;
export type SetReferralMutationFn = Apollo.MutationFunction<
  SetReferralMutation,
  SetReferralMutationVariables
>;

/**
 * __useSetReferralMutation__
 *
 * To run a mutation, you first call `useSetReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReferralMutation, { data, loading, error }] = useSetReferralMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useSetReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<SetReferralMutation, SetReferralMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetReferralMutation, SetReferralMutationVariables>(
    SetReferralDocument,
    options,
  );
}
export type SetReferralMutationHookResult = ReturnType<typeof useSetReferralMutation>;
export type SetReferralMutationResult = Apollo.MutationResult<SetReferralMutation>;
export type SetReferralMutationOptions = Apollo.BaseMutationOptions<
  SetReferralMutation,
  SetReferralMutationVariables
>;
export const GetTopUsersDocument = gql`
  query GetTopUsers($take: Int) {
    getTopUsers(take: $take) {
      ...UserCore
      score
    }
  }
  ${UserCoreFragmentDoc}
`;

/**
 * __useGetTopUsersQuery__
 *
 * To run a query within a React component, call `useGetTopUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopUsersQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetTopUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTopUsersQuery, GetTopUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopUsersQuery, GetTopUsersQueryVariables>(GetTopUsersDocument, options);
}
export function useGetTopUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTopUsersQuery, GetTopUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopUsersQuery, GetTopUsersQueryVariables>(
    GetTopUsersDocument,
    options,
  );
}
export type GetTopUsersQueryHookResult = ReturnType<typeof useGetTopUsersQuery>;
export type GetTopUsersLazyQueryHookResult = ReturnType<typeof useGetTopUsersLazyQuery>;
export type GetTopUsersQueryResult = Apollo.QueryResult<
  GetTopUsersQuery,
  GetTopUsersQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($username: String!) {
    getUserProfile(username: $username) {
      user {
        id
        name
        username
        image
      }
      stats {
        id
        rank
        score
        followersCount
        following
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  );
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const FollowUserDocument = gql`
  mutation FollowUser($userId: ID!) {
    followUser(userId: $userId) {
      ok
    }
  }
`;
export type FollowUserMutationFn = Apollo.MutationFunction<
  FollowUserMutation,
  FollowUserMutationVariables
>;

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFollowUserMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowUserMutation, FollowUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(
    FollowUserDocument,
    options,
  );
}
export type FollowUserMutationHookResult = ReturnType<typeof useFollowUserMutation>;
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<
  FollowUserMutation,
  FollowUserMutationVariables
>;
export const IdealistItemCore = gql`
  fragment IdealistItemCore on IdealistItem {
    id
    description
    link
    images {
      id
      src
    }
  }
`;
export const IdealistLocationCore = gql`
  fragment IdealistLocationCore on IdealistLocation {
    id
    name
    lat
    long
  }
`;
export const IdealistCore = gql`
  fragment IdealistCore on Idealist {
    id
    createdAt
    title
    image
    description
    isDraft
  }
`;
export const UserCore = gql`
  fragment UserCore on User {
    id
    username
    image
  }
`;
export const FeedIdealistWithUserStats = gql`
  fragment FeedIdealistWithUserStats on IdealistWithUserStats {
    idealist {
      id
      createdAt
      title
      description
      image
      isDraft
      creator {
        ...UserCore
      }
      location {
        id
        name
      }
      completedBy {
        name
        ...UserCore
      }
    }
    likeCount
    likedByUser
    completedByUser
  }
`;
export const LikeOrDislikeIdealist = gql`
  mutation LikeOrDislikeIdealist($id: ID!, $like: Boolean!) {
    likeOrDislikeIdealist(id: $id, like: $like) {
      listId
      likeCount
      likedByUser
    }
  }
`;
export const CompleteIdealist = gql`
  mutation CompleteIdealist($id: ID!) {
    completedIdealist(id: $id) {
      listId
      completedCount
      user {
        name
        ...UserCore
      }
    }
  }
  ${UserCore}
`;
export const GetFeedEvents = gql`
  query GetFeedEvents($after: ID, $take: Int, $userId: ID) {
    paginatedFeedEvents(after: $after, take: $take, userId: $userId) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          createdAt
          type
          list {
            ...FeedIdealistWithUserStats
          }
          follows {
            id
            createdAt
            follower {
              ...UserCore
            }
          }
          user {
            ...UserCore
          }
        }
      }
    }
  }
  ${FeedIdealistWithUserStats}
  ${UserCore}
`;
export const EditIdeaList = gql`
  mutation EditIdeaList($id: ID!, $input: IdealistInput!) {
    editIdeaList(id: $id, input: $input) {
      ...IdealistCore
      items {
        id
        description
      }
      location {
        ...IdealistLocationCore
      }
      images {
        id
        src
      }
    }
  }
  ${IdealistCore}
  ${IdealistLocationCore}
`;
export const CreateDraftIdeaList = gql`
  mutation CreateDraftIdeaList {
    createDraftIdeaList
  }
`;
export const GetIdeaList = gql`
  query GetIdeaList($id: ID!) {
    getIdeaList(id: $id) {
      idealist {
        ...IdealistCore
        items {
          ...IdealistItemCore
        }
        location {
          ...IdealistLocationCore
        }
        creator {
          name
          ...UserCore
        }
        completedBy {
          name
          ...UserCore
        }
        images {
          id
          src
        }
      }
      likeCount
      likedByUser
      completedByUser
    }
  }
  ${IdealistCore}
  ${IdealistItemCore}
  ${IdealistLocationCore}
  ${UserCore}
`;
export const DeleteIdealist = gql`
  mutation DeleteIdealist($id: ID!) {
    deleteIdealist(id: $id)
  }
`;
export const MakeIdealistPublic = gql`
  mutation MakeIdealistPublic($id: ID!) {
    makeIdealistPublic(id: $id)
  }
`;
export const GetPublicIdeaList = gql`
  query GetPublicIdeaList($id: ID!, $gRecaptchaToken: String!) {
    getPublicIdeaList(id: $id, gRecaptchaToken: $gRecaptchaToken) {
      idealist {
        ...IdealistCore
        items {
          ...IdealistItemCore
        }
        location {
          ...IdealistLocationCore
        }
        creator {
          name
          ...UserCore
        }
        completedBy {
          name
          ...UserCore
        }
        images {
          id
          src
        }
      }
      likeCount
    }
  }
  ${IdealistCore}
  ${IdealistItemCore}
  ${IdealistLocationCore}
  ${UserCore}
`;
export const GetInviteLinkForList = gql`
  query GetInviteLinkForList($id: ID!, $gRecaptchaToken: String!) {
    getInviteLinkForList(id: $id, gRecaptchaToken: $gRecaptchaToken) {
      link
    }
  }
`;
export const GetIdealists = gql`
  query GetIdealists(
    $after: ID
    $take: Int
    $userId: ID
    $includeDraft: Boolean
    $searchInput: String
  ) {
    paginatedIdeaLists(
      after: $after
      take: $take
      userId: $userId
      includeDraft: $includeDraft
      searchInput: $searchInput
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          ...FeedIdealistWithUserStats
        }
      }
    }
  }
  ${FeedIdealistWithUserStats}
  ${UserCore}
`;
export const AddContactSubmission = gql`
  mutation AddContactSubmission(
    $email: String!
    $name: String!
    $message: String!
    $type: ContactSubmissionType!
    $gRecaptchaToken: String!
  ) {
    addContactSubmission(
      email: $email
      name: $name
      message: $message
      type: $type
      gRecaptchaToken: $gRecaptchaToken
    ) {
      ok
    }
  }
`;
export const CompleteProfile = gql`
  mutation CompleteProfile($username: String!, $name: String!) {
    setUsername(username: $username) {
      ok
    }
    setName(name: $name) {
      ok
    }
  }
`;
export const IsEmailVerified = gql`
  query IsEmailVerified {
    isEmailVerified {
      ok
    }
  }
`;
export const VerifyProfile = gql`
  mutation VerifyProfile($token: String!) {
    verifyProfile(token: $token) {
      ok
    }
  }
`;
export const RequestPasswordReset = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordReset(username: $username) {
      ok
    }
  }
`;
export const VerifyPasswordReset = gql`
  query VerifyPasswordReset($token: String!) {
    verifyPasswordReset(token: $token) {
      ok
    }
  }
`;
export const PasswordReset = gql`
  mutation PasswordReset($token: String!, $password: String!, $gRecaptchaToken: String!) {
    passwordReset(token: $token, password: $password, gRecaptchaToken: $gRecaptchaToken) {
      ok
    }
  }
`;
export const GetInviteLink = gql`
  query GetInviteLink {
    getInviteLink {
      link
      invitedUsersSignedUpCount
    }
  }
`;
export const VerifyInviteLink = gql`
  query verifyInviteLink($id: String!) {
    verifyInviteLink(id: $id) {
      valid
      user {
        id
        name
      }
    }
  }
`;
export const SetReferral = gql`
  mutation setReferral($referralId: String!) {
    setReferral(referralId: $referralId) {
      ok
    }
  }
`;
export const GetTopUsers = gql`
  query GetTopUsers($take: Int) {
    getTopUsers(take: $take) {
      ...UserCore
      score
    }
  }
  ${UserCore}
`;
export const GetUserProfile = gql`
  query GetUserProfile($username: String!) {
    getUserProfile(username: $username) {
      user {
        id
        name
        username
        image
      }
      stats {
        id
        rank
        score
        followersCount
        following
      }
    }
  }
`;
export const FollowUser = gql`
  mutation FollowUser($userId: ID!) {
    followUser(userId: $userId) {
      ok
    }
  }
`;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CompleteIdealistResponse: ResolverTypeWrapper<CompleteIdealistResponse>;
  ContactSubmissionType: ContactSubmissionType;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  FeedEvent: ResolverTypeWrapper<FeedEvent>;
  FeedEventEdge: ResolverTypeWrapper<FeedEventEdge>;
  FeedEventType: FeedEventType;
  Follows: ResolverTypeWrapper<Follows>;
  GetUserProfileResponse: ResolverTypeWrapper<GetUserProfileResponse>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  IdeaListEdge: ResolverTypeWrapper<IdeaListEdge>;
  Idealist: ResolverTypeWrapper<Idealist>;
  IdealistImage: ResolverTypeWrapper<IdealistImage>;
  IdealistImageInput: IdealistImageInput;
  IdealistInput: IdealistInput;
  IdealistItem: ResolverTypeWrapper<IdealistItem>;
  IdealistItemInput: IdealistItemInput;
  IdealistLocation: ResolverTypeWrapper<IdealistLocation>;
  IdealistWithUserStats: ResolverTypeWrapper<IdealistWithUserStats>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InviteLinkResponse: ResolverTypeWrapper<InviteLinkResponse>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  LikeOrDislikeIdealistResponse: ResolverTypeWrapper<LikeOrDislikeIdealistResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaginatedConnection: ResolversTypes['PaginatedFeedEvents'] | ResolversTypes['PaginatedIdeaLists'];
  PaginatedEdge: ResolversTypes['FeedEventEdge'] | ResolversTypes['IdeaListEdge'];
  PaginatedFeedEvents: ResolverTypeWrapper<PaginatedFeedEvents>;
  PaginatedIdeaLists: ResolverTypeWrapper<PaginatedIdeaLists>;
  Query: ResolverTypeWrapper<{}>;
  SimpleOK: ResolverTypeWrapper<SimpleOk>;
  String: ResolverTypeWrapper<Scalars['String']>;
  User: ResolverTypeWrapper<User>;
  UserProfileStats: ResolverTypeWrapper<UserProfileStats>;
  VerifyInviteLinkResponse: ResolverTypeWrapper<VerifyInviteLinkResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean'];
  CompleteIdealistResponse: CompleteIdealistResponse;
  Date: Scalars['Date'];
  FeedEvent: FeedEvent;
  FeedEventEdge: FeedEventEdge;
  Follows: Follows;
  GetUserProfileResponse: GetUserProfileResponse;
  ID: Scalars['ID'];
  IdeaListEdge: IdeaListEdge;
  Idealist: Idealist;
  IdealistImage: IdealistImage;
  IdealistImageInput: IdealistImageInput;
  IdealistInput: IdealistInput;
  IdealistItem: IdealistItem;
  IdealistItemInput: IdealistItemInput;
  IdealistLocation: IdealistLocation;
  IdealistWithUserStats: IdealistWithUserStats;
  Int: Scalars['Int'];
  InviteLinkResponse: InviteLinkResponse;
  JSON: Scalars['JSON'];
  JSONObject: Scalars['JSONObject'];
  LikeOrDislikeIdealistResponse: LikeOrDislikeIdealistResponse;
  Mutation: {};
  PageInfo: PageInfo;
  PaginatedConnection:
    | ResolversParentTypes['PaginatedFeedEvents']
    | ResolversParentTypes['PaginatedIdeaLists'];
  PaginatedEdge: ResolversParentTypes['FeedEventEdge'] | ResolversParentTypes['IdeaListEdge'];
  PaginatedFeedEvents: PaginatedFeedEvents;
  PaginatedIdeaLists: PaginatedIdeaLists;
  Query: {};
  SimpleOK: SimpleOk;
  String: Scalars['String'];
  User: User;
  UserProfileStats: UserProfileStats;
  VerifyInviteLinkResponse: VerifyInviteLinkResponse;
};

export type CompleteIdealistResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompleteIdealistResponse'] = ResolversParentTypes['CompleteIdealistResponse'],
> = {
  completedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  listId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type FeedEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeedEvent'] = ResolversParentTypes['FeedEvent'],
> = {
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  follows?: Resolver<Maybe<ResolversTypes['Follows']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  list?: Resolver<Maybe<ResolversTypes['IdealistWithUserStats']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FeedEventType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedEventEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeedEventEdge'] = ResolversParentTypes['FeedEventEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FeedEvent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Follows'] = ResolversParentTypes['Follows'],
> = {
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  follower?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  following?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUserProfileResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetUserProfileResponse'] = ResolversParentTypes['GetUserProfileResponse'],
> = {
  stats?: Resolver<ResolversTypes['UserProfileStats'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdeaListEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdeaListEdge'] = ResolversParentTypes['IdeaListEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['IdealistWithUserStats'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Idealist'] = ResolversParentTypes['Idealist'],
> = {
  completedBy?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['IdealistImage']>>, ParentType, ContextType>;
  isDraft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['IdealistItem']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['IdealistLocation']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealistImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdealistImage'] = ResolversParentTypes['IdealistImage'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealistItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdealistItem'] = ResolversParentTypes['IdealistItem'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['IdealistImage']>>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealistLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdealistLocation'] = ResolversParentTypes['IdealistLocation'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealistWithUserStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdealistWithUserStats'] = ResolversParentTypes['IdealistWithUserStats'],
> = {
  completedByUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  idealist?: Resolver<ResolversTypes['Idealist'], ParentType, ContextType>;
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likedByUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteLinkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteLinkResponse'] = ResolversParentTypes['InviteLinkResponse'],
> = {
  invitedUsersSignedUpCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type LikeOrDislikeIdealistResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LikeOrDislikeIdealistResponse'] = ResolversParentTypes['LikeOrDislikeIdealistResponse'],
> = {
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likedByUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  listId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addContactSubmission?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddContactSubmissionArgs,
      'email' | 'gRecaptchaToken' | 'message' | 'name' | 'type'
    >
  >;
  completedIdealist?: Resolver<
    ResolversTypes['CompleteIdealistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCompletedIdealistArgs, 'id'>
  >;
  createDraftIdeaList?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deleteIdealist?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteIdealistArgs, 'id'>
  >;
  editIdeaList?: Resolver<
    ResolversTypes['Idealist'],
    ParentType,
    ContextType,
    RequireFields<MutationEditIdeaListArgs, 'id' | 'input'>
  >;
  followUser?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationFollowUserArgs, 'userId'>
  >;
  likeOrDislikeIdealist?: Resolver<
    ResolversTypes['LikeOrDislikeIdealistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLikeOrDislikeIdealistArgs, 'id' | 'like'>
  >;
  makeIdealistPublic?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<MutationMakeIdealistPublicArgs, 'id'>
  >;
  passwordReset?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationPasswordResetArgs, 'gRecaptchaToken' | 'password' | 'token'>
  >;
  requestPasswordReset?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationRequestPasswordResetArgs, 'username'>
  >;
  setName?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationSetNameArgs, 'name'>
  >;
  setReferral?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationSetReferralArgs, 'referralId'>
  >;
  setUsername?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationSetUsernameArgs, 'username'>
  >;
  verifyProfile?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<MutationVerifyProfileArgs, 'token'>
  >;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedConnection'] = ResolversParentTypes['PaginatedConnection'],
> = {
  __resolveType: TypeResolveFn<
    'PaginatedFeedEvents' | 'PaginatedIdeaLists',
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type PaginatedEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedEdge'] = ResolversParentTypes['PaginatedEdge'],
> = {
  __resolveType: TypeResolveFn<'FeedEventEdge' | 'IdeaListEdge', ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type PaginatedFeedEventsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedFeedEvents'] = ResolversParentTypes['PaginatedFeedEvents'],
> = {
  edges?: Resolver<Array<ResolversTypes['FeedEventEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedIdeaListsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedIdeaLists'] = ResolversParentTypes['PaginatedIdeaLists'],
> = {
  edges?: Resolver<Array<ResolversTypes['IdeaListEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  getIdeaList?: Resolver<
    ResolversTypes['IdealistWithUserStats'],
    ParentType,
    ContextType,
    RequireFields<QueryGetIdeaListArgs, 'id'>
  >;
  getInviteLink?: Resolver<ResolversTypes['InviteLinkResponse'], ParentType, ContextType>;
  getInviteLinkForList?: Resolver<
    ResolversTypes['InviteLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetInviteLinkForListArgs, 'gRecaptchaToken' | 'id'>
  >;
  getPublicIdeaList?: Resolver<
    ResolversTypes['IdealistWithUserStats'],
    ParentType,
    ContextType,
    RequireFields<QueryGetPublicIdeaListArgs, 'gRecaptchaToken' | 'id'>
  >;
  getStrings?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetStringsArgs, 'count'>
  >;
  getTopUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<QueryGetTopUsersArgs>
  >;
  getUserProfile?: Resolver<
    ResolversTypes['GetUserProfileResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserProfileArgs, 'username'>
  >;
  isEmailVerified?: Resolver<ResolversTypes['SimpleOK'], ParentType, ContextType>;
  paginatedFeedEvents?: Resolver<
    ResolversTypes['PaginatedFeedEvents'],
    ParentType,
    ContextType,
    Partial<QueryPaginatedFeedEventsArgs>
  >;
  paginatedIdeaLists?: Resolver<
    ResolversTypes['PaginatedIdeaLists'],
    ParentType,
    ContextType,
    Partial<QueryPaginatedIdeaListsArgs>
  >;
  verifyInviteLink?: Resolver<
    ResolversTypes['VerifyInviteLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryVerifyInviteLinkArgs, 'id'>
  >;
  verifyPasswordReset?: Resolver<
    ResolversTypes['SimpleOK'],
    ParentType,
    ContextType,
    RequireFields<QueryVerifyPasswordResetArgs, 'token'>
  >;
};

export type SimpleOkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimpleOK'] = ResolversParentTypes['SimpleOK'],
> = {
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProfileStats'] = ResolversParentTypes['UserProfileStats'],
> = {
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  following?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyInviteLinkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyInviteLinkResponse'] = ResolversParentTypes['VerifyInviteLinkResponse'],
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  CompleteIdealistResponse?: CompleteIdealistResponseResolvers<ContextType>;
  Date?: GraphQLScalarType;
  FeedEvent?: FeedEventResolvers<ContextType>;
  FeedEventEdge?: FeedEventEdgeResolvers<ContextType>;
  Follows?: FollowsResolvers<ContextType>;
  GetUserProfileResponse?: GetUserProfileResponseResolvers<ContextType>;
  IdeaListEdge?: IdeaListEdgeResolvers<ContextType>;
  Idealist?: IdealistResolvers<ContextType>;
  IdealistImage?: IdealistImageResolvers<ContextType>;
  IdealistItem?: IdealistItemResolvers<ContextType>;
  IdealistLocation?: IdealistLocationResolvers<ContextType>;
  IdealistWithUserStats?: IdealistWithUserStatsResolvers<ContextType>;
  InviteLinkResponse?: InviteLinkResponseResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  LikeOrDislikeIdealistResponse?: LikeOrDislikeIdealistResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginatedConnection?: PaginatedConnectionResolvers<ContextType>;
  PaginatedEdge?: PaginatedEdgeResolvers<ContextType>;
  PaginatedFeedEvents?: PaginatedFeedEventsResolvers<ContextType>;
  PaginatedIdeaLists?: PaginatedIdeaListsResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  SimpleOK?: SimpleOkResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserProfileStats?: UserProfileStatsResolvers<ContextType>;
  VerifyInviteLinkResponse?: VerifyInviteLinkResponseResolvers<ContextType>;
};
